import React, { useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  makeStyles,
  createStyles,
  Select,
  Button,
} from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { Link, navigate } from 'gatsby';
import SectionDescription from '../../components/section-description-new';
import FeaturedCardStatistic from '../../components/featured-card-statistic';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RegistrationJSON from '../../data/registration.json';
import CustomLink from '../../components/custom-link';
import ImageContainer from '../../components/image-container';
import axios from 'axios';
import { registerNewPartner } from '../../validators/register-partner-validator';

const useStyles = makeStyles((theme) =>
  createStyles({
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
    },
  })
);
const statistics = [
  {
    value: 3,
    text: 'DAYS',
  },
  {
    value: '3000+',
    text: 'ATTENDEES',
  },
  {
    value: '40+',
    text: 'SESSIONS',
  },
  {
    value: '90+',
    text: 'SPEAKERS',
  },
  {
    value: '46',
    text: 'COUNTRIES',
  },
];

const PartnersIndexPage = ({ pageContext: { _partners, _partnersYears, currentYear } }) => {
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const classes = useStyles();
  const { country } = RegistrationJSON;

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setValidationErrors({
      ...validationErrors,
      [name]: null,
    });
  };

  const handleSelect = (e, newValue) => {
    const { name } = newValue;

    setFormData({
      ...formData,
      country: name,
      countryCode: country.find((_country) => _country.name === name).dialCode,
    });

    setValidationErrors({
      ...validationErrors,
      country: null,
    });
  };

  const handleSubmit = (e) => {
    try {
      registerNewPartner
        .validate(formData, { abortEarly: false })
        .then(() => {
          axios
            .post(`${process.env.GATSBY_API_URL}register/partners`, formData)
            .then((_response) => {
              if (_response.status === 200) {
                navigate('/');
              }
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => {
          if (err && err.inner) {
            let _validationErrors = {};
            err.inner.forEach((e) => {
              _validationErrors[e.path] = e.message;
            });

            setValidationErrors(_validationErrors);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <SEO
        lang='en'
        title='Partners | Global Manufacturing & Industrialisation Summit | GMIS 2022'
        keywords='Manufacturing & Industrialisation Summit Partners, GMIS Summit 2022 Partners, GMIS Summit CO-CHAIRS, GMIS Summit 2022 Organising committee, Global manufacturing & Industrialisation Summit Foundation Partners, GMIS 2022 Strategic & Industry Partners'
        description='GMIS Summit UAE has an organizing committee & strategic & industry Partners for organizing Global Manufacturing & Industrialisation Summits. Join GMIS Summit 2022 today!'
      />
      <SectionContainerLayoutWithFilter
        title='Global Network'
        baseLink='/partners'
        // filters={_partnersYears}
        isDivider
        actions={
          <Link to='/partner-with-us'>
            <Button color='primary'>Become a Partner</Button>
          </Link>
        }>
        {/*<SectionDescription
          left
          image
          text='Elit nisi anim quis nisi nostrud sit dolor esse. Amet cupidatat ad laborum pariatur elit commodo dolor Lorem laboris velit ea ex. Reprehenderit velit officia anim aliqua. Labore voluptate reprehenderit labore nulla velit tempor sunt elit voluptate excepteur esse sunt dolore. Elit nisi anim quis nisi nostrud sit dolor esse. Amet cupidatat ad laborum pariatur elit commodo dolor Lorem laboris velit ea ex. Reprehenderit velit officia anim aliqua. Labore voluptate reprehenderit labore nulla velit tempor sunt elit voluptate excepteur esse sunt dolore. Elit nisi anim quis nisi nostrud sit dolor esse. Amet cupidatat ad laborum pariatur elit commodo dolor Lorem laboris velit ea ex. Reprehenderit velit officia anim aliqua. Labore voluptate reprehenderit labore nulla velit tempor sunt elit voluptate excepteur esse sunt dolore.'
        />
        <Box my={5}>
          <Grid container spacing={3} justify='center'>
            {statistics.map((_statistic, index) => (
              <Grid item xs={12} lg={2} key={index}>
                <FeaturedCardStatistic data={_statistic} isData />
              </Grid>
            ))}
          </Grid>
        </Box>
       <SectionContainerLayoutWithFilter title='Partner With us' />
        <Grid container spacing={8}>
          <Grid item container xs={12} lg={6} spacing={1}>
            <Grid item xs={12}>
              <TextField
                style={{ backgroundColor: 'transparent' }}
                onChange={handleFormChange}
                className={classes.textFieldLabelSpacing}
                label='First Name'
                name='firstName'
                fullWidth
                autoComplete={'off'}
                error={validationErrors.firstName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleFormChange}
                className={classes.textFieldLabelSpacing}
                label='Last Name'
                name='lastName'
                fullWidth
                autoComplete={'off'}
                error={validationErrors.lastName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleFormChange}
                className={classes.textFieldLabelSpacing}
                label='Email'
                name='email'
                fullWidth
                autoComplete={'off'}
                error={validationErrors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleFormChange}
                className={classes.textFieldLabelSpacing}
                label='Company Name'
                name='company'
                error={validationErrors.company}
                fullWidth
                autoComplete={'off'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleFormChange}
                className={classes.textFieldLabelSpacing}
                label='Job Title'
                name='jobTitle'
                error={validationErrors.jobTitle}
                fullWidth
                autoComplete={'off'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, newValue) => handleSelect(event, newValue)}
                options={country}
                autoComplete={'off'}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    className={classes.textFieldLabelSpacing}
                    {...params}
                    label='Country of Residence'
                    name='country'
                    error={validationErrors.country}
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} lg={2}>
              <TextField
                onChange={handleFormChange}
                autoComplete={'off'}
                className={classes.textFieldLabelSpacing}
                label={
                  formData.country ? country.find((_country) => _country.name === formData.country).dialCode : 'Code'
                }
                disabled
                name='code'
              />
            </Grid>
            <Box clone pl={2}>
              <Grid item xs={10} lg={10}>
                <TextField
                  className={classes.textFieldLabelSpacing}
                  label='Mobile Number'
                  name='mobile'
                  autoComplete={'off'}
                  onChange={handleFormChange}
                  error={validationErrors.mobile}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Typography variant='body1'>*</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Box>

            <Grid item container xs={12}>
              <Grid item xs={12} lg={10}>
                <Box my={5}>
                  <Typography variant='body1' color='textPrimary'>
                    Or Email us at{' '}
                    <CustomLink linkUrl='mailto:partners@gmisummit.com' linkText='partners@gmisummit.com' external />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Box my={5}>
                  <Button variant='contained' color='primary' fullWidth onClick={handleSubmit}>
                    SUBMIT
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} lg={6}>
            <Grid item xs={12}>
              <ImageContainer />
            </Grid>
          </Grid>
        </Grid> */}
        {/* <SectionContainerLayoutWithFilter title='Our Partners' /> */}

        {/* WITH CATERORIES =================  */}
        {/* <Grid container direction='row' spacing={3}>
          {_partnerCategories.map((_partnerCategory, index) => (
            <Grid key={index} item xs={12}>
              <Typography gutterBottom variant='h6' className='text-uppercase'>
                <Box my={3}>{_partnerCategory.name}</Box>
              </Typography>
              <Grid container spacing={3}>
                {_partnerCategory.partner.map(
                  (_partner, index) =>
                    // _partner.partnercategory.name === _partnerCategory ? (
                    _partner.year === currentYear && (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        {_partner.description ? (
                          <Link to={`/partners/${_partner.urlSlug}`}>
                            <img
                              src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                              alt={_partner.title || '#GMIS2021'}
                              style={{ width: '100%' }}
                            />
                          </Link>
                        ) : _partner.websiteUrl ? (
                          <a href={_partner.websiteUrl} target='_blank' rel='noreferrer'>
                            <img
                              src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                              alt={_partner.title || '#GMIS2021'}
                              style={{ width: '100%' }}
                            />
                          </a>
                        ) : (
                          <img
                            src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                            alt={_partner.title || '#GMIS2021'}
                            style={{ width: '100%' }}
                          />
                        )}
                      </Grid>
                    )
                  // ) : null
                )}
              </Grid>
            </Grid>
          ))}
        </Grid> */}

        {/* WITHOUT CATERORIES =================  */}

        <Grid container spacing={3}>
          {_partners.map((_partner, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              {_partner.description ? (
                <Link to={`/partners/${_partner.urlSlug}`}>
                  <img
                    src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                    alt={_partner.title || '#GMIS2021'}
                    style={{ width: '100%' }}
                  />
                </Link>
              ) : _partner.websiteUrl ? (
                <a href={_partner.websiteUrl} target='_blank' rel='noreferrer'>
                  <img
                    src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                    alt={_partner.title || '#GMIS2021'}
                    style={{ width: '100%' }}
                  />
                </a>
              ) : (
                <img
                  src={`${process.env.GATSBY_CMS_API_URL}/static/${_partner.file.code}`}
                  alt={_partner.title || '#GMIS2021'}
                  style={{ width: '100%' }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default PartnersIndexPage;
