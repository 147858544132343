import * as yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export let registerNewPartner = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Please enter a valid email').required('Email is required'),
  '00N6e00000MeoNX': yup.string().required('Company is required'),
  '00N6e00000MeoNN': yup.string().required('Job title is required'),
  '00N6e00000MeoNS': yup.string().required('Country of residence is required'),
  '00N6e00000MeoNX': yup.string().matches(phoneRegExp, 'Invalid mobile nnumber').required('Mobile is required'),
});
